$breakpoints: (
    xs: 0,
    sm: 480px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1800px,
);

@mixin media($media, $device: '', $orientation: '') {
    @if $orientation != '' {
        $media: #{$media} 'and (orientation: #{$orientation})';
    }

    @if $device != '' {
        $media: only #{$device} and #{$media};
    }

    @media #{$media} {
        @content;
    }
}

@function get_media_size($key, $additional) {
    @if map-has-key($breakpoints, $key) == false {
        @warn "`$breakpoints` does not contain #{$key}.";
    }
    @if $additional != 'only' and $additional != 'up' and $additional != 'down'
    {
        @warn "$additional must be only, up or down.";
    }

    $keys: map-keys($breakpoints);
    $index: index($keys, $key);
    $length: length($keys);
    $min: null;
    $max: null;

    @if $index > 1 {
        $min: map-get($breakpoints, nth($keys, $index));
    }
    @if $index < $length {
        $max: map-get($breakpoints, nth($keys, $index + 1)) - 1;
    }

    @if $additional == 'only' and $index > 1 and $index < $length {
        @return '(min-width:#{$min}) and (max-width:#{$max})';
    } @else if $additional == 'up' and $index > 1 {
        @return '(min-width:#{$min})';
    } @else if $additional == 'down' and $index < $length {
        @return '(max-width:#{$max})';
    }

    @warn "Error getting the right media size!";
    @return '';
}

@function get_media_sizes($include_directions: true) {
    $keys: map-keys($breakpoints);
    $length: length($keys);
    $directions: 'only', 'up', 'down';
    $values: ();

    @if $include_directions != true {
        $directions: 'only';
    }

    @each $key, $value in $breakpoints {
        $index: index($keys, $key);

        @each $direction in $directions {
            $size: get_size($key, $direction);

            @if $size != false {
                $values: map-merge(
                    $values,
                    (
                        length($values): (
                            key: $key,
                            size: $size,
                            direction: $direction,
                        ),
                    )
                );
            }
        }
    }

    @return $values;
}

@mixin media-breakpoint($name, $direction) {
    @if $direction == 'up' {
        @include media-breakpoint-up($name) {
            @content;
        }
    } @else if $direction == 'down' {
        @include media-breakpoint-down($name) {
            @content;
        }
    } @else {
        @include media-breakpoint-only($name) {
            @content;
        }
    }
}

// Herunder er fra bootstrap

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @return if(
        $n < length($breakpoint-names),
        nth($breakpoint-names, $n + 1),
        null
    );
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name) {
    $next: breakpoint-next($name);
    @return if($next, breakpoint-min($next) - 0.02px, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash infront.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name) {
    @return if(breakpoint-min($name) == null, '', '-#{$name}');
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name) {
    $min: breakpoint-min($name);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name) {
    $max: breakpoint-max($name);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper) {
    $min: breakpoint-min($lower);
    $max: breakpoint-max($upper);

    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $max == null {
        @include media-breakpoint-up($lower) {
            @content;
        }
    } @else if $min == null {
        @include media-breakpoint-down($upper) {
            @content;
        }
    }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name) {
    $min: breakpoint-min($name);
    $max: breakpoint-max($name);

    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $max == null {
        @include media-breakpoint-up($name) {
            @content;
        }
    } @else if $min == null {
        @include media-breakpoint-down($name) {
            @content;
        }
    }
}
