$palette-background: #fafafa;
$palette-dark-background: #2f3442;

$palette-text: #626262;
$palette-link: #2c2c2c;
$palette-link-hover: #121212;

$palette-dark-text: #d4d4d4;
$palette-dark-link: #d4d4d4;
$palette-dark-link-hover: #fff;

$palette-border: #e6e6e6;
$palette-dark-border: #232730;

$palette-green-500: #6abb6e;
$palette-green-400: lighten(saturate($palette-green-500, 5%), 5%);
$palette-green-600: darken(saturate($palette-green-500, 1%), 5%);

$palette-light-500: #ededed;
$palette-light-400: lighten(saturate($palette-light-500, 5%), 5%);
$palette-light-600: darken($palette-light-500, 5%);

$palette-dark-500: #2a2f3b;
$palette-dark-400: lighten(saturate($palette-dark-500, 5%), 5%);
$palette-dark-600: darken($palette-dark-500, 5%);

$palette-light-shadow: rgba(white, 0.3);
$palette-dark-shadow: rgba(black, 0.2);
