%montserrat {
    font-family: 'Montserrat';
    letter-spacing: -0.1px;

    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-feature-settings: 'kern' 1;
}

%montserrat-fat {
    @extend %montserrat;

    font-weight: 600;
    text-transform: uppercase;
}
