@import 'styles/aspect-ratio.scss';
@import 'styles/media.scss';
@import 'styles/colors.scss';

$border: 1px solid #e6e6e6;

.container {
    display: grid;
    grid-gap: 0;
    grid-template-areas: 'directions ingredients';
    grid-template-columns: 1fr 40%;
    grid-template-rows: auto;
}

.directions {
    grid-area: directions;
    padding: 0 15px 0 10px;
}

.ingredients {
    grid-area: ingredients;
    padding: 0 10px 0 15px;
}

.name,
.description {
    text-align: center;
}

.image {
    @include aspect-ratio(40, 25);

    img {
        position: absolute;
        top: 0;
        max-width: 100%;
    }
}

.inlineIngredient {
    background-color: $color-light-500;
    white-space: nowrap;
    padding: 0 4px;
    border-radius: 2px;
    font-weight: 600;
}

@include media-breakpoint-up(md) {
    .ingredients {
        border-left: $border;
    }
}

@include media-breakpoint-down(sm) {
    .container {
        grid-template-areas:
            'ingredients'
            'directions';
        grid-template-columns: 1fr;
    }

    .directions {
        padding: 15px 0 0;
    }

    .ingredients {
        border-bottom: $border;
        padding: 0 0 15px 0;
    }
}

.container {
    display: block;
    position: relative;
    height: 10px;
    margin-left: 50%;
    margin-bottom: 5px;
}

.item {
    display: block;
    position: absolute;
    height: 10px;
    opacity: 0.5;
    background-color: chocolate;
    border: 1px solid black;
}
