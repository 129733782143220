@import 'styles/colors.scss';

.bar {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    max-width: 100%;
    width: 0;
    height: 3px;
    background-color: $color-green-500;
    transition: width 0.2s ease;
}
