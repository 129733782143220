@import 'styles/colors.scss';
@import 'styles/fonts.scss';
@import 'styles/media.scss';

$headerHeight: 60px;
$header: $headerHeight + 1px;

$categoryWidth: 95px;
$menu-bezier: 0.05, 0.74, 0.27, 0.99;

.container {
    z-index: 100;

    position: fixed;
    bottom: 0;
    top: 0;
    right: auto;

    width: 375px;
    max-width: 100vw;

    display: grid;
    grid-template-columns: 1fr $categoryWidth;
    grid-gap: 0;
    grid-auto-rows: $header calc(100vh - #{$header});

    background-color: $color-navigation-medium;
    color: #788296;

    & > ul {
        list-style-type: none;
    }

    will-change: left;
    transition: transform 400ms cubic-bezier($menu-bezier),
        filter 400ms cubic-bezier($menu-bezier);
    transform: translateX(-280px);
    filter: drop-shadow(0 0 6px $color-navigation-border);

    a {
        color: inherit;
    }
}

.header {
    height: $headerHeight;

    display: flex;
    justify-content: center;
    align-items: center;

    grid-column: 1 / 3;
    grid-row: 1;

    background-color: $color-navigation-dark;
    border-bottom: 1px solid $color-navigation-border;
}

.categories {
    grid-column: 2;
    grid-row: 2;

    margin: 0;
    padding: 10px 0;

    background-color: $color-navigation-light;
}

.content {
    grid-column: 1;
    grid-row: 2;
    opacity: 0;

    border-right: 1px solid #232730;
    //    max-width: calc(100vw - 95px);
    background-color: #292e38;
    margin: 0;
    padding: 0;
    //    overflow-y: auto;

    &.visible {
        opacity: 1;
    }
    &:not(.visible) {
        pointer-events: none;
    }
}

.category {
    height: 55px;

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    grid-auto-rows: 30px 20px;

    cursor: default;

    &:hover {
        color: white;
    }

    & &Icon {
        place-self: end center;
        font-size: 20px;
    }

    &Name {
        place-self: start center;
        font-size: 10px;
        @extend %montserrat-fat;
    }
}

.item {
    height: 70px;

    border-bottom: 1px solid $color-navigation-border;
    cursor: pointer;

    &:hover {
        background-color: $color-navigation-dark;
        color: white;
    }

    & > a {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
        grid-auto-rows: 30px 35px;
    }

    &Name {
        place-self: end left;

        padding: 0 15px;

        @extend %montserrat-fat;
    }

    &Description {
        place-self: start left;

        padding: 0 15px;

        @extend %montserrat-fat;
        text-transform: unset;
    }
}

.category,
.item {
    &.active {
        color: white;
        border-left: 2px solid #6abb6e;
    }
    &:not(.active) {
        padding-left: 2px;
    }
}

.button {
    border: none;
    outline: none;
    background: none;
    color: inherit;
    font-size: 16px;
    height: 16px;
    padding: 0;
}

html {
    overflow: hidden;
}
body {
    transition: transform 400ms cubic-bezier($menu-bezier);
}

@include media-breakpoint-down(sm) {
    .container {
        left: -$categoryWidth;
        filter: drop-shadow(0 0 0 $color-navigation-border);

        body.open & {
            filter: drop-shadow(0 0 6px $color-navigation-border);
        }
    }

    body.open {
        transform: translateX(280px + $categoryWidth);
    }
}

@include media-breakpoint-up(md) {
    .button {
        display: none;
    }

    body.open .container {
        transform: translate(0px, 0px);
    }
}
