@import 'styles/media.scss';
@import 'styles/colors.scss';

.wrapper {
    --padding: 70px;

    @include media-breakpoint-down(md) {
        --padding: 50px;
    }

    @include media-breakpoint-down(sm) {
        --padding: 30px;
    }

    padding: 0 var(--padding);
    transition: padding 500ms ease;
    min-height: 100vh;

    &::before {
        position: fixed;
        content: '';
        display: block;
        border: 0 solid white;
        top: var(--padding);
        bottom: var(--padding);
        left: var(--padding);
        right: var(--padding);
        opacity: 0;
        pointer-events: none;

        animation-name: animateOpenBorder;
        animation-duration: 2s;
        animation-timing-function: ease-out;
    }
}

@keyframes animateOpenBorder {
    0% {
        opacity: 1;
        border-width: 50vh;
    }
    99% {
        opacity: 1;
        border-width: 0px;
    }
    100% {
        opacity: 0;
    }
}

@keyframes animateOpenOpacity {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    99% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.content {
    color: #ddd;
    padding: 20px 25px;
    min-height: calc(100vh - var(--padding) - var(--padding));
    transition: height 500ms ease;
    background: #092756;
    background: -moz-radial-gradient(
            0% 100%,
            ellipse cover,
            rgba(104, 128, 138, 0.4) 10%,
            rgba(138, 114, 76, 0) 40%
        ),
        -moz-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(
                        42,
                        60,
                        87,
                        0.4
                    )
                    100%),
        -moz-linear-gradient(-45deg, #670d10 0%, #092756 100%);
    background: -webkit-radial-gradient(
            0% 100%,
            ellipse cover,
            rgba(104, 128, 138, 0.4) 10%,
            rgba(138, 114, 76, 0) 40%
        ),
        -webkit-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(
                        42,
                        60,
                        87,
                        0.4
                    )
                    100%),
        -webkit-linear-gradient(-45deg, #670d10 0%, #092756 100%);
    background: -o-radial-gradient(
            0% 100%,
            ellipse cover,
            rgba(104, 128, 138, 0.4) 10%,
            rgba(138, 114, 76, 0) 40%
        ),
        -o-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(
                        42,
                        60,
                        87,
                        0.4
                    )
                    100%),
        -o-linear-gradient(-45deg, #670d10 0%, #092756 100%);
    background: -ms-radial-gradient(
            0% 100%,
            ellipse cover,
            rgba(104, 128, 138, 0.4) 10%,
            rgba(138, 114, 76, 0) 40%
        ),
        -ms-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(
                        42,
                        60,
                        87,
                        0.4
                    )
                    100%),
        -ms-linear-gradient(-45deg, #670d10 0%, #092756 100%);
    background: -webkit-radial-gradient(
            0% 100%,
            ellipse cover,
            rgba(104, 128, 138, 0.4) 10%,
            rgba(138, 114, 76, 0) 40%
        ),
        linear-gradient(
            to bottom,
            rgba(57, 173, 219, 0.25) 0%,
            rgba(42, 60, 87, 0.4) 100%
        ),
        linear-gradient(135deg, #670d10 0%, #092756 100%);
}

.top,
.bottom {
    height: var(--padding);
    transition: height 500ms ease;
    background-color: $color-background;
    position: sticky;
    color: rgb(28, 27, 32);
    display: flex;
}

.top,
.bottom,
.socialWrapper {
    animation-name: animateOpenOpacity;
    animation-duration: 3s;
    animation-timing-function: ease-out;
}

.top {
    top: 0;
    padding: 4px 0;
    align-items: flex-end;
    justify-content: space-between;

    .websiteName {
        font-size: 15px;
        text-transform: uppercase;
    }

    .contentName {
        font-size: 13px;
        margin-bottom: -1px;
        margin-left: -2px;
        text-transform: capitalize;
    }
}

.dotSeparater {
    margin: 0 8px;
    font-size: 12px;
}

.bottom {
    bottom: 0;
}

.socialWrapper {
    color: rgb(28, 27, 32);
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: var(--padding);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .socialIcon {
        display: flex;
        position: relative;
        margin: 8px 0;
        width: 30px;
        height: 30px;

        .icon {
            position: absolute;
            top: 0;
            left: 0;

            fill: white;
            stroke: $color-text;
            stroke-width: 0.4em;
            transition: all 1s ease;
        }

        .backgroundIcon {
            fill: $color-text-dark;
            opacity: 0;
            transition: all 1s ease;

            &.twitter {
                fill: $color-twitter;
            }

            &.linkedin {
                fill: $color-linkedin;
            }

            &.flickr {
                fill: $color-flickr;
            }
        }

        &:hover {
            .backgroundIcon {
                opacity: 0.5;
            }
        }
    }
}

.projectLink {
    transition: opacity 500ms ease;
    font-family: 'Lato';
    text-transform: uppercase;
    font-size: 12px;

    &:not(.activeLink) {
        opacity: 0.7;
    }

    &:hover {
        opacity: 1;
    }
}
