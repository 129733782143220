@import 'styles/palette.scss';

$color-navigation-dark: #272b35;
$color-navigation-medium: #292e38;
$color-navigation-light: #2b303b;
$color-navigation-border: #232730;

$color-background: white;
$color-border: $palette-border;

$color-text: $palette-text;
$color-link: $palette-link;
$color-link-hover: $palette-link-hover;

$color-text-dark: $palette-dark-text;
$color-link-dark: $palette-dark-link;
$color-link-hover-dark: $palette-dark-link-hover;

$color-green-400: $palette-green-400;
$color-green-500: $palette-green-500;
$color-green-600: $palette-green-600;

$color-light-400: $palette-light-400;
$color-light-500: $palette-light-500;
$color-light-600: $palette-light-600;

$color-dark-400: $palette-dark-400;
$color-dark-500: $palette-dark-500;
$color-dark-600: $palette-dark-600;

$color-light-shadow: $palette-light-shadow;
$color-dynamic-light-shadow: $palette-light-shadow;

$color-dark-shadow: $palette-dark-shadow;
$color-dynamic-dark-shadow: $palette-dark-shadow;

$color-input-background: white;

$color-twitter: #00aced;
$color-linkedin: #2867b2;
$color-flickr: #0062dd;
